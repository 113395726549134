<template>
  <div>
    <div class="container py-5">
      <div class="row mb-4">
        <div class="col-12 text-center">
          <h3>Select your school</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <form>
            <b-form-select v-model="selectedSchool" :disabled="existingSchool">
              <option :value="null" disabled>
                Select your school
              </option>
              <option v-for="school in schools" :key="school.rowid" :value="school">
                {{ school.company }}
              </option>
            </b-form-select>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center mt-4">
          <b-button variant="primary" :disabled="!selectedSchool" @click="nextNavigation()">
            Continue
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullPageLoader from '@/utils/mixins/FullPageLoader';

export default {
  name: 'SelectSchool',
  mixins: [FullPageLoader],
  computed: {
    existingSchool: {
      get: function () {
        return this.$store.getters['studentStore/getExistingSchool'];
      },
      set: function (school) {
        this.$store.commit('studentStore/setExistingSchool', school);
      }
    },
    schools: {
      get: function () {
        return this.$store.getters['studentStore/getSchools'];
      },
      set: function (schools) {
        this.$store.commit('studentStore/setSchools', schools);
      }
    },
    selectedSchool: {
      get: function () {
        return this.$store.getters['studentStore/getSelectedSchool'];
      },
      set: function (school) {
        this.$store.commit('studentStore/setSelectedSchool', school);
      }
    },
    step: {
      get: function () {
        return this.$store.getters['studentStore/getStep'];
      },
      set: function (step) {
        this.$store.commit('studentStore/setStep', step);
      }
    },
    userOrigin () {
      return this.$store.getters['studentStore/getUserOrigin'];
    },
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.$store.dispatch('studentStore/getOriginFromUser', this.user);
      this.initialDataLoad();
      this.incrementStep();
    },
    /**
     * On init() hit API to populate school dropdown
     * IF user is already logged in, set data and continue
     *
     * TODO: This should be broken up into multiple functions
     * One should call the api and return the promise
     * the other should process the data
     */
    initialDataLoad () {
      if (!this.schools.length) {
        this.$store.commit('studentStore/setStep', 1);
        this.showFullPageLoading(true);
        let axiosConnectionData = {
          method: 'get',
          url: this.SPENV.APP_URL + '/api/v2/schools',
          body: {}
        };
        this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
          .then(
            response => {
              this.schools = response.data;
              if (this.user && this.user.organization) {
                this.setSelectedSchool();
                this.setEmailFromLoggedInUser();
                this.nextNavigation();
              } else if (this.user && this.user.is_logged_in) {
                this.setEmailFromLoggedInUser();
              }
              this.showFullPageLoading(false);
            },
            error => {
              this.showFullPageLoading(false);
              console.log(error);
            }
          );
      }
    },
    /**
     * Prevent the user from using the browser back button
     */
    noBackwardsNavigate () {
      if (this.step === 3) {
        this.nextNavigation();
      }
    },
    /**
     * Navigation can only flow forward
     * only increment the Store step if it is smaller than current
     */
    incrementStep () {
      if (this.step < 1) {
        this.step = 1;
      }
    },
    /**
     * If the user is already logged in, pull email from existing data and set it in store
     * @return sets to store
     */
    setEmailFromLoggedInUser () {
      if (this.user.email) {
        this.$store.commit('user/setEmail', this.user.email);
      }
    },
    /**
     * Searches for logged in school id from student store eligible schools (retrieved from API)
     * @return sets to store
     */
    setSelectedSchool () {
      for (let i = 0; i < this.schools.length; i++) {
        if (this.schools[i].rowid === this.user.organization.id) {
          this.$store.commit('studentStore/setSelectedSchool', this.schools[i]);
          this.$store.commit('studentStore/setExistingSchool', true);
          break;
        }
      }
      this.showFullPageLoading(false);
    },
    /**
     * Continue to user details
     */
    nextNavigation () {
      if (this.user.is_logged_in && this.userOrigin != 'lti') {
        this.step = 3;
        this.$router.push({name: 'StudentPayment'});
      } else {
        this.step = 2;
        this.$router.push({name: 'StudentAccount'});
      }
    }
  }
};
</script>
